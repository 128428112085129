/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import StoreSwitcher from 'SourceComponent/StoreSwitcher';
import { MenuType } from 'Type/Menu';
import { getSortedItems } from 'Util/Menu';

import './MenuMobile.style';

/** @namespace Scandipwa/Component/MenuMobile/Component */
export class MenuMobileComponent extends PureComponent {
    static propTypes = {
        menu: MenuType.isRequired,
        activeMenuItemParent: PropTypes.instanceOf(Object).isRequired,
        handleMobileMenuBackClick: PropTypes.func.isRequired,
        handleMobileMenuParentItemClick: PropTypes.func.isRequired,
        handleMobileMenuItemClick: PropTypes.func.isRequired
    };

    renderBackToParentItem() {
        const { activeMenuItemParent, handleMobileMenuBackClick } = this.props;
        const parentExists = Object.keys(activeMenuItemParent).length && activeMenuItemParent.parent_id !== 0;

        if (!parentExists) {
            return <span />;
        }

        return (
            <button
              onClick={ () => handleMobileMenuBackClick(activeMenuItemParent) }
              block="MenuMobile"
              elem="BackButton"
            >
                <Icons
                  name="arrow_left"
                  width="9px"
                  height="15px"
                  fill="#70A3CA"
                  block="MenuMobile"
                  elem="BackIcon"
                />
                { activeMenuItemParent.title }
            </button>
        );
    }

    renderSubmenuPlusIcon(item) {
        const { handleMobileMenuParentItemClick } = this.props;

        return (
                <Icons
                  name="plus"
                  width="15px"
                  height="15px"
                  fill="#fff"
                  onClick={ () => handleMobileMenuParentItemClick(item) }
                />
        );
    }

    renderMenuListItem = (item) => {
        const { handleMobileMenuItemClick } = this.props;
        const { item_id, title, children } = item;
        const hasChildren = children && Object.keys(children).length > 0;

        return (
            <li
              block="MenuMobile"
              elem="Item"
              mods={ { hasChildren } }
              key={ item_id }
            >
                <button
                  block="MenuMobile"
                  elem="ItemButton"
                  mods={ { hasChildren } }
                  onClick={ () => handleMobileMenuItemClick(item) }
                >
                    { title }
                </button>
                { hasChildren && this.renderSubmenuPlusIcon(item) }
            </li>
        );
    };

    renderMenu() {
        const { menu, activeMenuItemParent } = this.props;

        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }
        const [{ children }] = categoryArray;

        const itemHasChildren = activeMenuItemParent
            && activeMenuItemParent.children
            && Object.keys(activeMenuItemParent.children).length;

        const childrenToShow = itemHasChildren ? activeMenuItemParent.children : children;
        const childrenArray = getSortedItems(Object.values(childrenToShow));

        return (
            <>
                <ul
                  block="MenuMobile"
                  elem="List"
                >
                    { childrenArray.map(this.renderMenuListItem) }
                </ul>
                { itemHasChildren ? <span /> : <StoreSwitcher /> }
            </>
        );
    }

    render() {
        return (
            <div block="MenuMobile">
                { this.renderBackToParentItem() }
                { this.renderMenu() }
            </div>
        );
    }
}

export default MenuMobileComponent;
