/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import { getActiveStoreCode } from 'Util/Helpers/getActiveStoreCode';
import history from 'Util/History';

import MenuMobile from './MenuMobile.component';

/** @namespace Scandipwa/Component/MenuMobile/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Component/MenuMobile/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandipwa/Component/MenuMobile/Container */
export class MenuMobileContainer extends SourceMenuContainer {
    __construct(props) {
        super.__construct(props);

        const {
            stack: activeMenuItemsStack = []
        } = history.location.state || {};

        this.state = {
            activeMenuItemsStack,
            activeMenuItemParent: {},
            menu: {}
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        handleMobileMenuItemClick: this.handleMobileMenuItemClick.bind(this),
        handleMobileMenuParentItemClick: this.handleMobileMenuParentItemClick.bind(this),
        handleMobileMenuBackClick: this.handleMobileMenuBackClick.bind(this)
    };

    handleMobileMenuItemClick(item) {
        const url = item.url.pathname || `/${getActiveStoreCode()}${item.url}`;

        if (url) {
            history.push(url);
        }
    }

    handleMobileMenuParentItemClick(item) {
        this.setState({ activeMenuItemParent: item });
    }

    handleMobileMenuBackClick(item) {
        const { menu } = this.state;
        const menuFirstLevelItem = Object.values(menu)[0].children;
        const newActiveMenu = menuFirstLevelItem[item.parent_id] || {};
        this.setState({ activeMenuItemParent: newActiveMenu });
    }

    render() {
        return (
            <MenuMobile
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobileContainer);
