import Icons from 'Component/Icons';
import ClickOutside from 'SourceComponent/ClickOutside';
import {
    StoreSwitcher as SourceStoreSwitcher
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import './StoreSwitcher.style';

/** @namespace Scandipwa/Component/StoreSwitcher/Component */
export class StoreSwitcherComponent extends SourceStoreSwitcher {
    renderStoreList = (item) => {
        const { value, storeLinkUrl, label } = item;

        return (
            <a href={ storeLinkUrl } key={ value } block="StoreSwitcher" elem="Link">
                { label }
            </a>
        );
    };

    renderDesktopStoreSwitcher() {
        const {
            storeList,
            onStoreSwitcherOutsideClick,
            onStoreSwitcherClick,
            isOpened,
            storeLabel,
            device
        } = this.props;

        const mods = { isOpen: isOpened };

        return (
            <div block="StoreSwitcher">
                <ClickOutside onClick={ onStoreSwitcherOutsideClick }>
                    <button
                      block="StoreSwitcher"
                      elem="Title"
                      mods={ mods }
                      onClick={ onStoreSwitcherClick }
                    >
                        { storeLabel }
                        <Icons
                          name="arrow_down"
                          width="14px"
                          height="8px"
                          fill={ device.isMobile ? '#fff' : '#0E0F11' }
                          block="StoreSwitcher"
                          elem="ArrowIcon"
                          mods={ { isOpened } }
                        />
                    </button>

                    <div block="StoreSwitcher" elem="StoreList" mods={ mods }>
                        { storeList.map(this.renderStoreList) }
                    </div>
                </ClickOutside>
            </div>
        );
    }

    render() {
        const { storeList } = this.props;

        if (storeList.length <= 1) {
            return null;
        }

        return this.renderDesktopStoreSwitcher();
    }
}

export default StoreSwitcherComponent;
