import { connect } from 'react-redux';

import {
    DEFAULT_NAVIGATION_TABS_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationTabsContainer as SourceNavigationTabsContainer
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';

export {
    mapStateToProps,
    mapDispatchToProps,
    DEFAULT_NAVIGATION_TABS_STATE
};

/** @namespace Scandipwa/Component/NavigationTabs/Container */
export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
