import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';

import './Menu.style';

/** @namespace Scandipwa/Component/Menu/Component */
export class MenuComponent extends SourceMenu {
    renderFirstLevel = (item) => {
        const { item_id, item_class } = item;

        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';

        return (
            <li
              block="Menu"
              elem="Item"
              key={ item_id }
              mods={ { isHideOnDesktop } }
            >
                { this.renderFirstLevelItems(item) }
                { this.renderSubMenuDesktopItems(item) }
            </li>
        );
    };

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <div block="Menu" elem="MainCategories">
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformation(true) }
            </div>
        );
    }

    renderSubMenuTail = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          style={ {
              position: 'absolute', top: '-13px', left: '50%', transform: 'translateX(-50%)'
          } }
        >
            { /* eslint-disable-next-line max-len */ }
            <path d="M6.92702 2.29148C8.09204 0.348341 10.908 0.348342 12.073 2.29148L18.4678 12.9574C19.6666 14.9569 18.2262 17.5 15.8948 17.5H3.10521C0.773765 17.5 -0.666638 14.9569 0.532228 12.9574L6.92702 2.29148Z" fill="white" />
        </svg>
    );

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                { this.renderSubMenuTail() }
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    };
}

export default MenuComponent;
