/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { CHECKOUT_URL } from '@scandipwa/scandipwa/src/route/Checkout/Checkout.config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Header from 'Component/Header/Header.component';
import { PRODUCTS_PAGE_URL, SEARCH_PAGE_URL } from 'Component/SearchField/SearchFIeld.config';
import {
    DEFAULT_HEADER_STATE as SOURCE_DEFAULT_HEADER_STATE,
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { translateUrl } from 'Util/Helpers/translateUrl';
import history from 'Util/History';

export const DEFAULT_HEADER_STATE = SOURCE_DEFAULT_HEADER_STATE;

/** @namespace Scandipwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandipwa/Component/Header/Container */
export class HeaderContainer extends SourceHeaderContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            showMyAccountLogin: false,
            showMobileMenu: false,
            isScrolling: false
        };
    }

    containerProps = () => {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            isWishlistLoading,
            renderOnlySearcher
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            isScrolling
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isProductsPage = pathname.split(/[/.]/).includes(translateUrl(PRODUCTS_PAGE_URL));
        const isSearchPage = pathname.split(/[/.]/).includes(translateUrl(SEARCH_PAGE_URL));

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            isProductsPage,
            isSearchPage,
            showMyAccountLogin,
            device,
            isWishlistLoading,
            firstname: this.getUserName(),
            isScrolling,
            renderOnlySearcher
        };
    };

    containerFunctions = {
        ...this.containerFunctions,
        onToggleMobileMenu: this.onToggleMobileMenu.bind(this)
    };

    componentDidMount() {
        const { device } = this.props;
        this.handleHeaderVisibility();
        super.componentDidMount();

        if (device.isMobile) {
            history.listen(() => {
                this.closeMobileMenu();
            });
        }
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        this.setState({ isScrolling: !(window.scrollY === 0) });
    }

    onToggleMobileMenu() {
        document.querySelector('body').classList.toggle('isBlocked');
        this.setState((prevState) => ({ ...prevState, showMobileMenu: !prevState.showMobileMenu }));
    }

    closeMobileMenu() {
        document.querySelector('body').classList.remove('isBlocked');
        this.setState({ showMobileMenu: false });
    }

    render() {
        return (
            <Header
              { ...this.state }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
