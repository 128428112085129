import Link from 'Component/Link';
import MenuMobile from 'Component/MenuMobile';
import SearchField from 'Component/SearchField';
import { LOT_SEARCH_URL } from 'Route/LotSearch/LotSearch.config';
import {
    Header as SourceHeader
} from 'SourceComponent/Header/Header.component';
import { CART_OVERLAY, CUSTOMER_WISHLIST, SEARCH } from 'SourceComponent/Header/Header.config';
import StoreSwitcher from 'SourceComponent/StoreSwitcher';
import { translateUrl } from 'Util/Helpers/translateUrl';

import './Header.style';

/** @namespace Scandipwa/Component/Header/Component */
export class HeaderComponent extends SourceHeader {
    renderHeader() {
        const { device } = this.props;
        return device.isMobile ? this.renderHeaderMobile() : this.renderHeaderDesktop();
    }

    renderHeaderDesktop() {
        const {
            isProductsPage,
            isSearchPage
        } = this.props;

        return (
            <>
                { this.renderLogo() }
                <div block="Header" elem="Menu">
                    { this.renderMenu() }
                    { this.renderExtraButton() }
                    { isProductsPage || isSearchPage ? null : this.renderSearchField(false, true) }
                    <StoreSwitcher />
                </div>
            </>
        );
    }

    renderHeaderMobile() {
        const { onToggleMobileMenu, showMobileMenu } = this.props;

        return (
            <>
                { this.renderLogo() }
                <div block="Header" elem="MobileRightWrapper">
                    <button onClick={ onToggleMobileMenu } block="Hamburger" mods={ { isOpen: showMobileMenu } }>
                        <span />
                        <span />
                        <span />
                    </button>
                </div>
                { this.renderMobileMenu() }
            </>
        );
    }

    renderMobileMenu() {
        const { showMobileMenu } = this.props;

        if (!showMobileMenu) {
            return null;
        }

        return (
            <div block="Header" elem="MobileMenuWrapper">
                <MenuMobile />
            </div>
        );
    }

    renderLogo(isVisible = false) {
        const { isLoading, showMobileMenu } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <Link
              to="/"
              aria-label="Osavi"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible, showMobileMenu } }
              key="logo"
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderExtraButton() {
        const { showMobileMenu } = this.props;
        return (
            <Link to={ translateUrl(LOT_SEARCH_URL) } block="Header" elem="ExtraButton" mods={ { showMobileMenu } }>
                { __('Search certificates') }
            </Link>
        );
    }

    renderSearchField(isVisible = false, isNavigationSearch) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
              isNavigationSearch={ isNavigationSearch }
            />
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device,
            showMobileMenu,
            renderOnlySearcher,
            isScrolling
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        if (renderOnlySearcher) {
            return this.renderSearchField();
        }

        return (
            <section block="Header" elem="Wrapper">
                <header
                  block="Header"
                  mods={ {
                      name, isHiddenOnMobile, isCheckout, showMobileMenu, isScrolling
                  } }
                  ref={ this.logoRef }
                >
                    { this.renderHeader() }
                </header>
            </section>
        );
    }
}

export default HeaderComponent;
