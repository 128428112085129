import { Suspense } from 'react';

import SearchIcon from 'Component/SearchIcon';
import {
    SearchField as SourceSearchField,
    SearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SearchField.override.style';

/** @namespace Scandipwa/Component/SearchField/Component */
export class SearchFieldComponent extends SourceSearchField {
    handleSearch = () => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };

    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            isNavigationSearch
        } = this.props;

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive, isNavigationSearch } }
                  placeholder={ isNavigationSearch ? '' : __('Enter what you are looking for ...') }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { this.renderSearchIcon() }
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay
                      clearSearch={ this.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </div>
        );
    }

    renderSearchIcon() {
        return (
            <div
              block="SearchField"
              elem="SearchIcon"
              role="button"
              tabIndex="0"
              onClick={ this.handleSearch }
              aria-label={ __('Search') }
            >
                <SearchIcon />
            </div>
        );
    }
}

export default SearchFieldComponent;
